<template>
	<div
		:class="['shopping-cart-container', show ? 'show' : '']"
		ref="sccontainer"
	>
		<div class="hide-click" @click="hideCart"></div>
		<div :class="['shopping-cart', show ? 'show' : '']">
			<h1>
				<button class="close" @click="hideCart">
					<i class="fas fa-xmark fa-2x"></i>
				</button>
				<i class="fas fa-shopping-basket"></i>&ensp;Winkelmandje
			</h1>
			<div class="products">
				<div
					class="product"
					v-for="(product, index) in products"
					:key="product.data.id"
				>
					<NuxtLink
						:to="`/shop/product/${
							product.data.id
						}-${encodeURIComponent(product.data.name)}`"
						class="--image"
					>
						<img
							:src="`https://cdn.macabram.be/${product.data.imageUrl}`"
							alt=""
						/>
						<div class="--price">
							€{{
								parseFloat(product.data.price / 100)
									.toFixed(2)
									.replace(".", ",")
							}}
						</div>
					</NuxtLink>
					<div class="--info">
						<div class="--top">
							<h2 class="--title">
								{{ product.data.name }}
								<i
									class="fas fa-palette --customized"
									v-if="product.customData"
								></i>
							</h2>
							<h3 class="--total-price">
								€{{
									parseFloat(
										(product.data.price * product.amount) /
											100
									)
										.toFixed(2)
										.replace(".", ",")
								}}
							</h3>
						</div>
						<p class="--desc sm-hide">
							{{ product.data.description }}
						</p>
						<div
							v-if="
								product.data['@id'] !==
								'/api/public/v2/products/7'
							"
							class="--actions"
						>
							<NuxtLink
								:to="`/shop/product/${
									product.data.id
								}-${encodeURIComponent(product.data.name)}`"
								class="btn btn-tertiary --goto"
							>
								<i class="fas fa-info"></i>
							</NuxtLink>
							<div class="--amount">
								<button
									:disabled="product.amount === 1"
									@click="
										product.amount > 1
											? product.amount--
											: null
									"
									class="--less btn btn-sm btn-warning btn-outline"
								>
									<i class="fas fa-minus"></i>
								</button>
								<FormKit
									type="number"
									min="1"
									max="50"
									v-model="product.amount"
								/>
								<button
									:disabled="product.amount === 50"
									@click="
										product.amount < 50
											? product.amount++
											: null
									"
									class="--add btn btn-sm btn-success btn-outline"
								>
									<i class="fas fa-plus"></i>
								</button>
							</div>
							<button
								@click="store.removeProduct(index)"
								class="--delete btn btn-sm btn-error"
							>
								<i class="fas fa-trash"></i
								><span class="sm-hide">&ensp;Verwijderen</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="total">
				<h2>
					Totaal:
					<span class="--total-price">€{{ calcTotalPrice() }}</span>
				</h2>
			</div>
			<div class="actions">
				<button @click="emptyCart" class="btn btn-error btn-outline">
					<i class="fas fa-trash-alt"></i>&ensp;Alles verwijderen
				</button>
				<NuxtLink v-if="products.length > 0" to="/shop/order">
					<button class="btn btn-success">
						<i class="fas fa-arrow-right"></i>&ensp;Bestellen
					</button>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.shopping-cart-container {
	overflow: hidden !important;
	z-index: 25;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.5s ease;
	display: flex;
	flex-direction: row;

	&.show {
		opacity: 1;
		pointer-events: all;
	}

	.hide-click {
		height: 100vh;
		width: 75vw;
		cursor: pointer;
	}

	.shopping-cart {
		position: absolute;
		top: 0;
		right: -35vw;
		background-color: #fff;
		box-shadow: 0 0 5px #ccc;
		width: 35vw;
		height: 100dvh;
		transition: right 0.5s ease;
		display: flex;
		flex-direction: column;

		padding: 1.5em 1em;

		h1 {
			position: relative;
			text-align: center;
			height: 5vh;

			.close {
				border: none;
				outline: none;
				padding: 0.5em;
				aspect-ratio: 1/1;
				cursor: pointer;
				background: none;
				color: #ccc;
				position: absolute;
				left: 0;
				top: -20px;
				display: none;
				width: 40px;
				height: 40px;
				display: grid;
				place-content: center;

				transition: color 0.5s ease, rotate 0.5s var(--cubic-animation);

				&:hover {
					rotate: 90deg;
					color: var(--secondary);
				}
			}
		}

		.products {
			height: 85vh;
			padding: 0 0 0 1em;
			position: relative;
			display: flex;
			flex-direction: column;
			max-height: 85vh;
			overflow-y: auto;
			gap: 20px;

			.product {
				display: flex;
				gap: 1em;

				.--image {
					width: 100px;
					height: 100px;
					position: relative;
					transition: box-shadow 0.5s ease;
					border-radius: var(--border-radius);

					&:hover {
						box-shadow: var(--box-shadow);
					}

					.--price {
						color: #000;
						position: absolute;
						bottom: 0;
						right: 0;
						border-bottom-right-radius: var(--border-radius);
						border-top-left-radius: var(--border-radius);
						background-color: #fff;
						padding: 0.15em 0.3em;
					}

					img {
						width: 100px;
						height: 100px;
						object-fit: cover;
						border-radius: var(--border-radius);
					}
				}

				.--info {
					display: flex;
					flex-direction: column;
					flex-grow: 1;

					.--top {
						display: flex;
						align-items: center;

						.--title {
							margin-bottom: 0;
							display: flex;
							align-items: center;
							gap: 0.5em;

							.--customized {
								color: var(--gray-dark);
								font-size: small;
							}
						}

						.--total-price {
							margin-bottom: 0;
							margin-left: auto;
							margin-right: 10px;
						}
					}

					.--desc {
						display: block;
						max-width: calc(20vw - 100px);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: auto;
					}

					.--actions {
						display: flex;
						flex-direction: row;
						gap: 1em;
						justify-content: space-around;

						.--goto {
							width: 45px;
							height: 45px;
							display: grid;
							place-content: center;
						}

						.--amount {
							display: flex;

							.--less {
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
								height: auto;
							}
							:deep(.formkit-outer) {
								margin-bottom: 0;
								height: 100%;
							}
							:deep(.formkit-wrapper) {
								height: 100%;
							}
							:deep(.formkit-inner) {
								border-radius: 0;
								box-shadow: inset var(--fk-border-box-shadow);
								width: 60px;
								height: 100%;
							}
							:deep(.formkit-input) {
								text-align: center;

								&::-webkit-outer-spin-button,
								&::-webkit-inner-spin-button {
									appearance: none;
									margin: 0;
								}
							}
							.--add {
								border-top-left-radius: 0;
								border-bottom-left-radius: 0;
								height: auto;
							}
						}
						.--delete {
							height: 100%;
						}
					}
				}
			}

			&:empty::after {
				content: "Er zijn geen producten gevonden";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				text-align: center;
				color: #aaa;
				transform: translate(-50%, -50%);
			}
		}

		.total {
			margin-left: auto;
			margin-right: 10px;
		}

		.actions {
			height: 5vh;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			gap: 2em;

			button,
			a {
				flex-grow: 1;
			}
			a button {
				width: 100%;
			}
		}

		&.show {
			right: 0;
		}
	}

	@media only screen and (max-width: 800px) {
		.hide-click {
			width: 0;
		}
		.--delete {
			width: 45px;
			height: 45px;
		}
		.--actions {
			margin-top: auto;
		}
		.sm-hide {
			display: none !important;
		}
		.shopping-cart {
			width: 100vw;
			padding: 1.5em 0.5em;

			h1 .close {
				top: 50%;
				translate: 0 -50%;
				width: 50px;
				height: 50px;
			}

			.products {
				padding: 0 0.5em;
			}
			.total {
				margin-top: 10px;
			}
		}
	}
}
</style>

<script setup>
import { useShoppingCartStore } from "~~/stores/shoppingcart";
import { storeToRefs } from "pinia";

const store = useShoppingCartStore();
const { products } = storeToRefs(store);

const { $bus } = useNuxtApp();

const show = ref(false);

const hideCart = () => {
	show.value = false;
};

$bus.on("showCart", () => {
	show.value = true;
});

$bus.on("hideCart", hideCart);

const emptyCart = () => {
	store.$reset();
};

const calcTotalPrice = () => {
	let total = 0;

	products.value.forEach((p) => (total += (p.amount * p.data.price) / 100));

	return parseFloat(total).toFixed(2).replace(".", ",");
};
</script>
