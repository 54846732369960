<template>
	<div>
		<NuxtLoadingIndicator
			color="repeating-linear-gradient(to right,#4e3241 0%,#ca8f41 100%)"
		/>
		<NuxtErrorBoundary>
			<main>
				<Header />
				<NuxtPage />
				<AppFooter v-if="showFooter" />
				<CookieModal v-if="!showedCookieModal" />
			</main>
			
			<template #error="{ error, clearError }">
				<div class="err">
					<h1 class="icon"><i class="fas fa-bug"></i></h1>
					<h1>Oeps! Er heeft zich een error voorgedaan!</h1>
					<h3>
						Geen zorgen, dit is waarschijnlijk een bug. <br>
						We doen ons best om deze zo snel mogelijk te verhelpen.
					</h3>
					<code>{{ error }}</code>
					<button class="btn btn-success btn-outline mt-1" @click="clearError({ redirect: '/' })">
						<i class="fas fa-home"></i>&ensp;Terug naar de homepagina
					</button>
				</div>
			</template>
		</NuxtErrorBoundary>
		<div
			v-if="!maintenance_status?.enabled"
			:class="['scroll-top', showScrollToTop ? '' : '--hidden']"
			@click="scrollToTop"
		>
			<i class="fas fa-chevron-up"></i>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.err {
	width: 100vw;
	height: 100vh;

	position: absolute;
	top: 0;
	left: 0;
	z-index: 50;

	display: grid;
	place-content: center;
	background-color: #fff;

	font-family: "Lexend", sans-serif;
	text-align: center;

	.icon {
		color: var(--error-main);
		font-size: 4rem;
		margin-bottom: 0;
	}
}
</style>

<script setup lang="ts">
import { useCookieStore } from "@/stores/cookie";
import { storeToRefs } from "pinia";

const { path } = useRoute();
const showFooter = ref(true);

const { data: maintenance_status } = await getFromApi(
	"/config/maintenance",
	"GET"
);

if (path == "/maintenance") {
	showFooter.value = false;
}

if (maintenance_status?.enabled == "true" && path !== "/maintenance") {
	await navigateTo("/maintenance");
	showFooter.value = false;
}

// Load settings
const settings = useSettings()
await callOnce(async () => {
	const { data } = await getFromApi('/settings')
	settings.value = data
})

const cookieStore = useCookieStore();
const { showedCookieModal } = storeToRefs(cookieStore);

const showScrollToTop = ref(false);

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});
};

onMounted(() => {
	window.addEventListener("scroll", () => {
		if (window.scrollY > 250) showScrollToTop.value = true;
		else showScrollToTop.value = false;
	});
});
</script>
