import { defineStore } from "pinia"

export const useCookieStore = defineStore('cookie', {
    state: () => ({
        showedCookieModal: false
    }),
    actions: {
        acceptCookies() {
            this.showedCookieModal = true
        }
    },
    persist: true
})