<template>
	<footer class="footer">
		<div class="content">
			<div class="links">
				<div class="link">
					<a href="mailto:info@macabram.be"
						><i class="fa-solid fa-envelope"></i
					></a>
				</div>
				<div class="link">
					<a
						href="https://www.instagram.com/mac_a_bram/"
						target="_blank"
						><i class="fa-brands fa-instagram"></i
					></a>
				</div>
				<div class="link">
					<a href="tel:+32494478764"
						><i class="fa-solid fa-phone"></i
					></a>
				</div>
			</div>
			<h3 class="site">
				<i class="far fa-copyright"></i>&ensp; Mac à Bram - {{ year }}
				<span
					>&ensp;|&ensp;<NuxtLink to="/legal"
						><i class="fas fa-scale-unbalanced"></i></NuxtLink
				></span>
			</h3>
		</div>
		<NuxtLink external to="https://tijls.be" target="_blank" class="copy">
			Made with&ensp;<i class="far fa-heart"></i>&ensp;by&ensp;<i
				class="far fa-copyright"
			></i>&ensp;Tijl Schipper
		</NuxtLink>
	</footer>
</template>

<script setup>
const year = computed(() => {
	const date = new Date();
	const formatter = new Intl.DateTimeFormat("nl-BE", { year: "numeric" });
	return formatter.format(date);
});
</script>
