export const useUserStore = defineStore('user', {
    state: () => ({
        first_name: '',
        last_name: '',
        token: null,
        roles: null,
        state: ""
    }),
    getters: {
        fullName: (state) => `${state.first_name} ${state.last_name}`,
        isLoggedIn: (state) => state.token !== null
    },
    actions: {
        logout(){
            this.first_name = ''
            this.last_name = ''
            this.token = null
            this.roles = null
            this.state = ""
        }
    },
    persist: {
        storage: piniaPluginPersistedstate.cookies({
            sameSite: "lax",
            domain: "macabram.be",
            maxAge: 3600
        })
    }
})