export default defineNuxtPlugin(() => {

    const checkToken = async () => {
        await getFromApi('/auth/verify', 'POST').catch(async (err) => {
            await navigateTo({
                path: '/login'
            })  
        })
    }

    return {
        provide: {
            checkToken
        }
    }
})