function deepEqual(newObj, orgObj) {
	const ok = Object.keys;

	let lenghtOk = ok(newObj).length == ok(orgObj).length;

	let dataOk = ok(newObj).every((key) => {
		if (typeof newObj[key] == "string") {
			return newObj[key] === orgObj[key];
		} else {
			return deepEqual(newObj[key], orgObj[key]);
		}
	});

	let tx = typeof newObj,
		ty = typeof orgObj;
	return newObj && orgObj && tx === "object" && tx === ty
		? lenghtOk && dataOk
		: newObj === orgObj;
}

export const useShoppingCartStore = defineStore("shoppingCart", {
	state: () => ({
		products: [],
		totalPrize: 0,
	}),
	actions: {
		removeProduct(index) {
			if (index == "all") {
				this.products = [];
			} else {
				this.products.splice(index, 1);
			}
		},
		addProduct(prod, data) {
			if (this.products.filter((p) => p.data["@id"] == prod["@id"]).length > 0) {
				//Check for data
				if (data) {
					const index = this.products.findIndex((p) => {
						return (
							p.data["@id"] == prod["@id"] &&
							deepEqual(data, p.customData)
						);
					});

					console.log(index);

					//Check if data is the same
					if (index !== -1) {
						this.products[index].amount++;
					} else {
						//Create new instance
						this.products.push({
							amount: 1,
							customData: data ?? false,
							data: prod,
						});
					}
				} else {
					const index = this.products.findIndex((p) => {
						return p.data["@id"] == prod["@id"] && !p.customData;
					});
					this.products[index].amount++;
				}
			} else {
				this.products.push({
					amount: 1,
					customData: data ?? false,
					data: prod,
				});
			}
		},
	},
	persist: {
		storage: piniaPluginPersistedstate.localStorage,
	},
});
