<template>
	<div>
		<header class="header">
			<div v-if="settings?.banner" class="banner">{{ settings?.banner }}</div>
			<nav :class="windowTop >= 250 ? '' : '--hidden'">
				<NuxtLink to="/" class="brand">
					<div
						class="logo"
						:style="{ '--logo': `url(${logoSrc})` }"
					></div>
					<h2 class="m-0">Mac à Bram</h2>
				</NuxtLink>
				<button @click="showCollapse = !showCollapse" class="expand">
					<i class="fas fa-bars fa-lg"></i>
				</button>
				<ul :class="[showCollapse ? '--show' : '']">
					<li>
						<NuxtLink to="/">Home</NuxtLink>
					</li>
					<li v-if="settings?.enableWebshop">
						<NuxtLink to="/shop">Webshop</NuxtLink>
					</li>
					<li v-if="settings?.enableWebshop && settings?.enableOrder" class="collapse-ml-auto">
						<div
							ref="toggleCart"
							class="shopping-cart-toggle"
							@click="showCart"
						>
							<ClientOnly
								><span class="badge font-montserrat">{{
									calcAmnt()
								}}</span></ClientOnly
							>
							<i class="fas fa-shopping-basket"></i>
						</div>
					</li>
					<ClientOnly>
						<div class="dropdown user-dropdown" v-if="isLoggedIn">
							<div class="icon">
								<i class="fas fa-user"></i>
							</div>
							<div class="dropdown-menu">
								<li>
									<NuxtLink to="/dashboard"
										><i class="fas fa-user"></i
									></NuxtLink>
								</li>
								<div
									class="admin"
									v-if="roles?.includes('ROLE_ADMIN')"
								>
									<li>
										<NuxtLink to="/admin/order/manual"
											><i
												class="fas fa-file-invoice-dollar"
											></i
										></NuxtLink>
									</li>
									<li>
										<NuxtLink to="/admin"
											><i class="fas fa-gears"></i
										></NuxtLink>
									</li>
								</div>
								<li>
									<NuxtLink to="/logout"
										><i
											class="fas fa-arrow-right-from-bracket"
										></i
									></NuxtLink>
								</li>
							</div>
						</div>
						<li v-else>
							<NuxtLink to="/login"
								><i class="fas fa-arrow-right-to-bracket"></i
							></NuxtLink>
						</li>
					</ClientOnly>
				</ul>
			</nav>
		</header>

		<LazyClientOnly>
			<ShoppingCart />
		</LazyClientOnly>
	</div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useShoppingCartStore } from "@/stores/shoppingcart";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const shoppingCartStore = useShoppingCartStore();
const isLoggedIn = computed(() => userStore.isLoggedIn);
const { roles } = storeToRefs(userStore);
const { products } = storeToRefs(shoppingCartStore);
const settings = useSettings()

const showCollapse = ref(false);
const windowTop = ref(0);
const { $bus } = useNuxtApp();
const route = useRoute();
const $img = useImage();
const logoSrc = $img("/logo.png", { format: "webp" });

const calcAmnt = () => {
	let amt = 0;

	products.value.forEach((p) => (amt += parseInt(p.amount)));

	return amt == 0 ? "" : amt;
};

const showCart = () => {
	$bus.emit("showCart");
};
const onScroll = () => {
	//@ts-ignore
	windowTop.value = window.top?.scrollY;
};

watch(
	() => route.fullPath,
	() => {
		showCollapse.value = false;
	}
);

onMounted(() => {
	window.addEventListener("scroll", onScroll);

	$bus.on("default_show_header", () => {
		window.removeEventListener("scroll", onScroll);
		windowTop.value = 251;
	});

	$bus.on("default_hide_header", () => {
		window.addEventListener("scroll", onScroll);
		windowTop.value = 0;
	});
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", onScroll);
});
</script>

<style lang="scss" scoped>
.header {
	width: 100vw;
	position: fixed;
	top: 0;

	z-index: 20;

	.banner {
		width: 100vw;
		padding: 0.5em 1em;
		background-color: var(--tertiary);
		color: #fff;
		text-align: center;
	}

	nav {
		max-height: 80px;
		height: 10vh;
		display: flex;
		flex-direction: row;
		padding: 0.5em 1em;
		align-items: center;
		box-shadow: var(--box-shadow-light);
		opacity: 1;
		background-color: rgb(255, 255, 255);
		transition: height 0.5s ease, opacity 0.5s ease;

		&.--hidden {
			opacity: 0 !important;
			height: 0px;
			user-select: none;
			pointer-events: none;
		}

		.brand {
			display: flex;
			text-decoration: none;
			color: #000;
			display: flex;
			align-items: center;
			transition: text-decoration 0.5s ease;
			margin-right: auto;

			&:hover {
				text-decoration: underline;
			}

			.logo {
				height: 70px;
				width: 70px;
				background-image: var(--logo);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				margin-right: 15px;
			}
		}

		ul {
			list-style: none;
			margin: 0;
			display: flex;
			flex-direction: row;
			align-items: center;

			.dropdown {
				position: relative;

				.icon {
					cursor: pointer;
					color: #000;
					max-width: 100%;
					height: 100%;
					padding: 1em;
					position: relative;

					&::after {
						content: "";
						top: 50%;
						left: 50%;
						translate: -50% -50%;
						border-radius: 100vmax;
						position: absolute;
						width: 30px;
						height: 30px;
						border: 1.5px solid var(--secondary);
					}
				}

				&:hover .dropdown-menu {
					opacity: 1;
					visibility: visible;
					pointer-events: all;
				}

				.dropdown-menu {
					position: absolute;
					left: 50%;
					translate: -50% -5px;
					opacity: 0;
					visibility: hidden;
					pointer-events: none;
					transition: opacity 0.5s ease, visibility 0.5s ease;

					display: flex;
					flex-direction: column;
					border-radius: var(--border-radius);
					background-color: #fff;
					box-shadow: var(--box-shadow);

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 50%;
						translate: -50% -50%;
						width: 10px;
						height: 10px;
						background-color: white;
						rotate: 45deg;
						clip-path: polygon(0% 0%, 0% 100%, 100% 0%, 0% 0%);
						box-shadow: var(--box-shadow);
					}

					.admin {
						border-top: 1px solid var(--gray);
						border-bottom: 1px solid var(--gray);

						li {
							&:last-child,
							&:first-of-type {
								border-radius: 0;
							}
						}
					}

					li {
						transition: backdrop-filter 0.5s ease;
						a {
							display: grid;
							place-content: center;
							&::after {
								display: none;
							}
						}
						&:first-child {
							border-radius: var(--border-radius)
								var(--border-radius) 0 0;
						}
						&:last-child {
							border-radius: 0 0 var(--border-radius)
								var(--border-radius);
						}

						&:hover {
							backdrop-filter: brightness(90%);
						}
					}
				}
			}

			li {
				a {
					color: #000;
					max-width: 100%;
					height: 100%;
					text-decoration: none;
					padding: 1em;
					position: relative;
					overflow: hidden;

					&::after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 0%;
						height: 5px;
						background-color: var(--tertiary);
						border-radius: var(--border-radius);
						transition: width 0.5s var(--cubic-animation);
					}

					&:hover::after {
						width: 100%;
					}
				}
				.shopping-cart-toggle {
					color: #000;
					cursor: pointer;
					max-width: 100%;
					padding: 1em;
					height: 100%;
					position: relative;
					transition: color 0.25s ease;

					&:hover {
						color: var(--secondary);
					}

					span.badge:not(:empty) {
						position: absolute;
						width: 22.5px;
						height: 22.5px;
						font-size: 12px;
						color: #fff;
						display: grid;
						place-content: center;
						border-radius: 100vmax;
						background-color: var(--secondary);
						top: 5%;
						right: 5%;
						font-family: "Lexend", sans-serif;
					}
				}
			}
		}

		.expand {
			display: none;
			outline: none;
			background: none;
			border: none;
			cursor: pointer;
			padding: 0.5em;
		}

		@media only screen and (max-width: 600px) {
			justify-content: space-between;
			background-color: #fff;
			max-height: max-content;
			height: max-content;
			flex-wrap: wrap;

			.brand {
				.logo {
					height: 60px;
					width: 60px;
				}
			}

			ul {
				width: 100vw;

				&:not(.--show) {
					display: none;
				}

				.collapse-ml-auto {
					margin-left: auto;
				}
			}

			.expand {
				display: inline-block;
			}
		}
		@media only screen and (min-width: 600px) and (max-width: 800px) {
			justify-content: center;
			background-color: #fff;

			.brand {
				.logo {
					height: 60px;
					width: 60px;
				}
			}
		}
	}
}
</style>
