//@ts-nocheck

import { callWithNuxt } from "nuxt/app";
import { storeToRefs } from "pinia";
import { useUserStore } from "~~/stores/user";

export const getFromApi = async (
	path: string,
	method: "GET" | "POST" | "PATCH" | "DELETE" = "GET",
	body: any = null,
	cb: Function = () => {},
	server: boolean = true
): Promise<{
	data: Any;
	dataRef: Ref;
	error: FetchError | null;
	errorRef: Ref<FetchError | null>;
	pending: Ref;
	refresh: Function;
}> => {
	const userStore = useUserStore();
	const config = useRuntimeConfig();
	const { token } = storeToRefs(userStore);
	const { path: currentRoute } = useRoute();
	const nuxtApp = useNuxtApp();

	let headers = {};
	if (userStore.isLoggedIn) {
		headers.Authorization = `Bearer ${token.value}`;
	}
	if (method == "PATCH") {
		headers["content-type"] = "application/merge-patch+json";
	} else if (path.includes(".csv")) {
		headers["content-type"] = "text/csv";
	} else {
		headers["Accept"] = "application/ld+json";
	}

	// console.log(
	// 	`%cFETCH:%c[${method}]%c[STARTING]%c ${path}`,
	// 	"background-color: #f1c40f;color: white;padding: 2px;",
	// 	"background-color: #3498db;color: white;padding: 2px;",
	// 	"background-color: #2ecc71;color: white;padding: 2px;",
	// 	"background-color; transparent; color: initial;padding: 2px;"
	// );

	const { data, error, pending, refresh } = await useFetch(encodeURI(path), {
		baseURL: `${
			path === "/auth" ||
			path.includes("/stripe/") ||
			path.includes("/mail/") ||
			path.includes("/forgot_password") ||
			path.includes("/send_verification_email") ||
			path.includes("/suspend_account") ||
			path.includes("/config/")
				? config.public.apiUrl.replace("/v2", "/")
				: config.public.apiUrl
		}`,
		method: method,
		body: body,
		headers: headers,
		server: server
	});

	watch(error, (err) => {
		console.error(error.value);
		if (error.value !== null) {
			console.error("[fetch response error]", error.value);
			if (error.value.statusCode == 401 && currentRoute !== "/login") {
				callWithNuxt(nuxtApp, () => {
					return navigateTo({
						path: "/logout",
						query: {
							toLogin: true,
							path: currentRoute,
						},
					});
				});
			} else {
				return cb(response);
			}
		}
	});

	// console.groupCollapsed(
	// 	`%cFETCH:%c[${method}]%c[END]%c ${path}`,
	// 	"background-color: #f1c40f;color: white;padding: 2px;",
	// 	"background-color: #3498db;color: white;padding: 2px;",
	// 	"background-color: #e67e22;color: white;padding: 2px;",
	// 	"background-color; transparent; color: initial;padding: 2px;"
	// );
	// console.log(data.value)
	// console.warn(error.value)
	// console.groupEnd()

	return {
		data: data.value,
		dataRef: data,
		error: error.value,
		errorRef: error,
		pending: pending,
		refresh,
	};
};
