<template>
    <div :class="cookieModalClass">
        <div class="icon">
            <i class="fas fa-cookie-bite"></i>
        </div>
        <div class="disclamer">
            <p class="text">
                Macabram.be gebruikt cookies, maar enkel noodzakelijke.
            </p>
            <NuxtLink to="/legal#cookies"><i class="fas fa-chevron-right"></i> Lees meer</NuxtLink>
            <button @click="acceptCookies" class="btn btn-info btn-fw btn-outline">Oké!</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useCookieStore } from "@/stores/cookie"
const cookieStore = useCookieStore()
const cookieModalClass = ref(['cookies'])

const acceptCookies = () => {
    cookieStore.acceptCookies()
    cookieModalClass.value.push('hideCookieModal')
}
</script>

<style lang="scss" scoped>
.cookies{
    z-index: 10;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: var(--border-radius);
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    padding: 1.5em 2em;
    display: flex;
    flex-direction: row;
    gap: 2em;
    transition: right 1s ease;

    &.hideCookieModal{
        right: -110%;
    }

    .icon{
        height: auto;
        display: grid;
        place-content: center;
        font-size: 4em;
        color: #84563C;
    }

    .disclamer{
        max-width: 11em;

        .text{
            margin-bottom: 2.5px;
        }

        a{
            margin-bottom: 5px;
            display: inline-block;
        }
    }
}
</style>